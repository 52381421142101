import React, { useEffect, useRef } from 'react';
import Hls from 'hls.js';
import { styled } from '@stitches/react';


const StyledHLSPlayer = styled("video", {
    position: "absolute", 
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
    objectFit: "cover",
})

const HLSPlayer = ({ url, className, style }) => {
    const videoRef = useRef(null);
    const hlsRef = useRef(null);
    const retryCount = useRef(0); // Track retry attempts
    const maxRetries = 5; // Limit retries to avoid infinite loops

    useEffect(() => {
        const video = videoRef.current;

        // Function to initialize HLS
        const initializeHLS = () => {
            if (Hls.isSupported() && url) {
                const hls = new Hls({
                    liveSyncDuration: 1, // Matches server segment duration (2s)
                    liveMaxLatencyDuration: 5, // Add buffer for network delays
                    lowLatencyMode: true, // Enable low-latency playback
                    manifestLoadingMaxRetry: 5, // Retry manifest loading before failing
                    manifestLoadingRetryDelay: 1500, // Retry delay for manifest
                    fragLoadingMaxRetry: 5, // Retry fragment loading
                    fragLoadingRetryDelay: 1500, // Retry delay for fragments
                    levelLoadingMaxRetry: 5, // Retry loading levels
                    levelLoadingRetryDelay: 1500, // Retry delay for levels
                });

                hlsRef.current = hls;
                hls.loadSource(url);
                hls.attachMedia(video);

                hls.on(Hls.Events.MANIFEST_PARSED, () => {
                    video.muted = true;
                    tryPlay(video); // Use retry logic
                });

                // Handle HLS errors 👇
                hls.on(Hls.Events.ERROR, (_, data) => {
                    console.error('HLS Error:', data);
                    if (data.fatal) {
                        switch (data.type) {
                            case Hls.ErrorTypes.NETWORK_ERROR:
                                console.warn('Network error - retrying...');
                                retryStream();
                                break;

                            case Hls.ErrorTypes.MEDIA_ERROR:
                                console.warn('Media error - recovering...');
                                hls.recoverMediaError();
                                break;

                            default:
                                console.warn('Unrecoverable error - restarting...');
                                retryStream();
                                break;
                        }
                    }
                });

                // Visibility change listener 👇
                const handleVisibilityChange = () => {
                    if (!document.hidden) {
                        tryPlay(video);
                    }
                };
                document.addEventListener('visibilitychange', handleVisibilityChange);

                // Cleanup on unmount
                return () => {
                    document.removeEventListener('visibilitychange', handleVisibilityChange);
                    hls.destroy();
                };
            }
        };

        // Function to retry play
        const tryPlay = (video, attemptsLeft = 5) => {
            video
                .play()
                .catch((err) => {
                    console.warn(`Playback failed. Retrying... (${attemptsLeft} attempts left)`, err);
                    if (attemptsLeft > 0) {
                        setTimeout(() => tryPlay(video, attemptsLeft - 1), 1500);
                    }
                });
        };

        // Retry function for stream errors
        const retryStream = () => {
            if (retryCount.current < maxRetries) {
                retryCount.current += 1;
                console.log(`Retrying stream... Attempt ${retryCount.current}`);
                setTimeout(() => initializeHLS(), 1000);
            } else {
                console.error('Max retries reached. Stream unavailable.');
            }
        };

        // Initialize HLS on mount
        initializeHLS();
    }, [url]); // Re-run when the URL changes

    return (
        
            <StyledHLSPlayer
                ref={videoRef}
                autoPlay
                muted
                playsInline
                className={className}
                style={style}
            />
    );
};

export default HLSPlayer;

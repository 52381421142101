import React, { useState, useEffect } from "react";
import { Box, Text, Flex, Avatar, DropdownMenu } from "@radix-ui/themes";
import colors from "./colors";
import logo from "./atom-logo.svg";
import { keyframes, styled } from "@stitches/react";
import dashboardIcon from "../icons/dashboard.svg";
import cameraIcon from "../icons/camera.svg";
import insightIcon from "../icons/insights.svg";
import eyalIcon from "../icons/eyal.svg";
import trackingIcon from "../icons/tracking.svg";
import documentationIcon from "../icons/documentation.svg";
import dropdownIcon from "../icons/dropdown.svg";
import logoutIcon from "../icons/logout.svg";
import {
  MdKeyboardDoubleArrowLeft,
  MdOutlineKeyboardDoubleArrowRight,
} from "react-icons/md";
import { Outlet, useNavigate } from "react-router";
import { useAppContext } from "../context/appContext";
import { getInitials } from "../utils";
import { limitedProjects, sampleImageUrl } from "../utils/constants";
import api from "../api/api";
import useIsMobile from "../hooks/is-mobile";

const Nav = styled(Box, {
  width: "100%",
  borderBottomRightRadius: "20px",
  borderBottomLeftRadius: "20px",
  background: colors.base,
  padding: "10px 50px 12px 20px",
  position: "fixed",
  top: 0,
  left: 0,
  zIndex: 1000,
});

const DashboardContent = styled(Box, {
  paddingTop: "60px",
  // background: colors.background,
  height: "100vh",
});
const SideMenu = styled(Box, {
  height: "100vh",
  width: "240px",
  paddingTop: "80px",
  paddingBottom: 20,
  borderRadius: "0px 0px 15px 15px",
  background: colors.secondary,
  boxShadow: "0px 2px 6px 0px rgba(183, 181, 188, 0.12)",
  border: "1px solid var(#E6EBF9, rgba(101, 99, 200, 0.25))",
  transition: "width 0.3s ease",
  top: 0,
  left: 0,
  position: "fixed",
  marginTop: "50px",
});

const StyledMenuItem = styled(Flex, {
  justifyContent: "start",
  alignItems: "center",
  padding: 20,
  columnGap: 20,
  cursor: "pointer",
  position: "relative",
  transition: "background-color 0.3s ease",
  backgroundColor: "transparent",

  "&::before": {
    content: '""',
    position: "absolute",
    left: 0,
    width: 0,
    height: "100%",
    backgroundColor: colors.active,
    transition: "width 0.3s ease",
  },

  // Conditional styling for active state
  variants: {
    active: {
      true: {
        backgroundColor: "white",
        "&::before": {
          width: "5px",
        },
      },
      false: {
        backgroundColor: "transparent",
        "&::before": {
          width: "0",
        },
      },
    },
    disabled: {
      true: {
        opacity: 0.4,
        cursor: "not-allowed",
        backgroundColor: "#E6EBF9",
        color: "#a0a0a0",
        "&::before": {
          backgroundColor: "transparent",
        },
      },
    },
  },
});

const MenuControl = styled(Flex, {
  justifyContent: "start",
  alignItems: "center",
  columnGap: 20,
  cursor: "pointer",
  paddingLeft: 20,
  paddingRight: 20,
  marginTop: 50,
});

const TriggerButton = styled("button", {
  all: "unset",
  cursor: "pointer",
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "50%",
  background: "white",
  width: "40px",
  height: "40px",
  textAlign: "center",
});

const MenuItem = ({
  id,
  icon,
  alt,
  collapsed,
  title,
  onClick,
  active,
  path,
  disabled,
}) => {
  const navigate = useNavigate();
  const handleOnClick = () => {
    if (disabled) {
      return;
    }
    navigate(path);
    onClick(id);
  };
  return (
    <StyledMenuItem onClick={handleOnClick} active={active} disabled={disabled}>
      <img src={icon} alt={alt} />
      {!collapsed && (
        <Text size="3" color="violet">
          {title}
        </Text>
      )}
    </StyledMenuItem>
  );
};

const menuItems = [
  {
    id: "dashboard",
    icon: dashboardIcon,
    alt: "dashboard icon",
    title: "Dashboard",
    collapsed: false,
    path: "/dashboard",
    disabled: true,
  },
  {
    id: "flying-eye",
    icon: cameraIcon,
    alt: "camera icon",
    title: "Flying eye",
    collapsed: false,
    path: "/dashboard/camera",
    disabled: false,
  },
  {
    id: "site-insights",
    icon: insightIcon,
    alt: "insight icon",
    title: "Site Insights",
    collapsed: false,
    path: "/dashboard/insights",
    disabled: false,
  },
  {
    id: "eyal-safety",
    icon: eyalIcon,
    alt: "Eyal icon",
    title: "Eyal Safety",
    collapsed: false,
    path: "/dashboard/eyal-safety",
    disabled: false,
  },
  {
    id: "progress-tracking",
    icon: trackingIcon,
    alt: "Tracking icon",
    title: "Dwg Poc",
    collapsed: false,
    path: "/dashboard/progress-tracking",
    disabled: false,
  },
  {
    id: "documentation",
    icon: documentationIcon,
    alt: "Documentation icon",
    title: "Documentation",
    collapsed: false,
    path: "/dashboard",
    disabled: true,
  },
];

const Dashboard = () => {
  const { user, selectedProject, projects, selectProject, logoutUser } =
    useAppContext();
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(true);
  const [activeMenuId, setActiveMenuId] = useState(null);
  const canAccessAdmin = user?.access_level === "master";
  const canShowProjectSelection = projects.length > 1;

  const isMobile = useIsMobile();

  const handleProjectChange = (project) => {
    selectProject(project);
  };

  const toBackOffice = () => {
    window.open(process.env.REACT_APP_ADMIN_URL, "_blank");
  };

  const checkIsDisabled = (item) => {
    if (item.id === "eyal-safety" && !selectedProject?.siteId) {
      return true;
    }
    if (item.id === "eyal-safety" && isMobile) {
      return true;
    }
    if (limitedProjects.includes(selectedProject?.name)) {
      return item.id !== "site-insights";
    }
    return item.disabled;
  };

  return (
    <Box>
      <Nav>
        <Flex align="center">
          <Flex justify="start" align="center" gapX="15px">
            <img src={logo} alt="logo" height="45px" width="45px" />
            <Text size="5" style={{ color: "white" }} weight="bold">
              ATOM
            </Text>
          </Flex>
          <Box
            ml={{
              sm: "120px",
              initial: "20px",
            }}
          >
            {canShowProjectSelection ? (
              <DropdownMenu.Root dir="rtl">
                <DropdownMenu.Trigger style={{ cursor: "pointer" }}>
                  <Flex align="center" gapX="15px" style={{ color: "white" }}>
                    <Text size="3">
                      {selectedProject?.name ?? "Undefined Project"}
                    </Text>
                    <img
                      src={dropdownIcon}
                      alt="dropdown icon"
                      style={{ marginTop: "5px" }}
                    />
                  </Flex>
                </DropdownMenu.Trigger>
                <DropdownMenu.Content size="3">
                  {projects.map((project) => (
                    <DropdownMenu.Item
                      key={project.id}
                      onClick={() => handleProjectChange(project)}
                    >
                      {project.name}
                    </DropdownMenu.Item>
                  ))}
                </DropdownMenu.Content>
              </DropdownMenu.Root>
            ) : (
              <Text size="3" style={{ color: "white" }}>
                {selectedProject?.name ?? "Undefined Project"}
              </Text>
            )}
          </Box>
          <Flex align="center" ml="auto" gapX="30px">
            <img src={logoutIcon} alt="logout icon" onClick={logoutUser} />
            <DropdownMenu.Root>
              <DropdownMenu.Trigger style={{ cursor: "pointer" }}>
                <TriggerButton>
                  <Avatar
                    radius="full"
                    size="3"
                    style={{ background: "white" }}
                    variant="soft"
                    fallback={getInitials(user?.name)}
                  />
                </TriggerButton>
              </DropdownMenu.Trigger>
              <DropdownMenu.Content size="2">
                <DropdownMenu.Item
                  onClick={() => navigate("/dashboard/settings")}
                >
                  Edit Profile
                </DropdownMenu.Item>
                {canAccessAdmin && (
                  <DropdownMenu.Item onClick={() => toBackOffice()}>
                    Back office
                  </DropdownMenu.Item>
                )}
                <DropdownMenu.Item
                  onClick={() => navigate("/dashboard/link-acc-account")}
                >
                  Link ACC Account
                </DropdownMenu.Item>
              </DropdownMenu.Content>
            </DropdownMenu.Root>
          </Flex>
        </Flex>
      </Nav>
      <Box>
        <SideMenu style={{ width: collapsed ? "80px" : "240px" }}>
          {menuItems.map((item) => (
            <MenuItem
              id={item.id}
              key={item.id}
              icon={item.icon}
              alt={item.alt}
              collapsed={collapsed}
              title={item.title}
              path={item.path}
              disabled={checkIsDisabled(item)}
              active={item.id === activeMenuId}
              onClick={(id) => setActiveMenuId(id)}
            />
          ))}
          <MenuControl onClick={() => setCollapsed((col) => (col = !col))}>
            {collapsed ? (
              <MdOutlineKeyboardDoubleArrowRight
                fontSize="30"
                color={colors.active}
              />
            ) : (
              <MdKeyboardDoubleArrowLeft fontSize="30" color={colors.active} />
            )}
            {!collapsed && (
              <Text size="3" color="violet">
                Collapse Menu
              </Text>
            )}
          </MenuControl>
        </SideMenu>
        <DashboardContent style={{ paddingLeft: collapsed ? "80px" : "240px" }}>
          <Outlet />
        </DashboardContent>
      </Box>
    </Box>
  );
};

export default Dashboard;

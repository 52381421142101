export const sampleImageUrl =
  "https://images.unsplash.com/photo-1502823403499-6ccfcf4fb453?&w=256&h=256&q=70&crop=focalpoint&fp-x=0.5&fp-y=0.3&fp-z=1&fit=crop";

export const defaultFloors = [
  "floor -2",
  "floor -1",
  "floor 0",
  "floor 1",
  "floor 2",
  "floor 3",
  "floor 4",
  "floor 5",
];

export const defaultElements = ["Ceiling", "Walls"];

export const defaultObjects = ["bottom-reinforcement", "top-reinforcement"];

export const limitedProjects = ["Pardesia Bridge"];

import React, { useState } from "react";
import { styled } from "@stitches/react";
import * as Popover from "@radix-ui/react-popover";
import { Box, Text } from "@radix-ui/themes";
import dropdownIcon from "../icons/dropdown.svg";

const InputBox = styled("button", {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "9px",
  width: "100%",
  backgroundColor: "#f4f7fd",
  border: "1px solid #d2d8f2",
  borderRadius: "6px",
  fontSize: "14px",
  color: "#2b2b52",
  cursor: "pointer",
});

const PopoverContent = styled(Popover.Content, {
  border: "1px solid #d2d8f2",
  borderRadius: "12px",
  backgroundColor: "#fff",
  padding: "12px",
  boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
  zIndex: 10,
});

const StyledInput = styled("input", {
  border: "1px solid #d2d8f2",
  borderRadius: "8px",
  padding: "8px",
  width: "100%",
  fontSize: "14px",
  color: "#2b2b52",
});

const TimePicker = ({ label, value, onChange }) => {
  return (
    <Box>
      <Popover.Root>
        <Popover.Trigger asChild>
          <InputBox>
            <Text>{value || label}</Text>
            <img src={dropdownIcon} alt="dropdown" />
          </InputBox>
        </Popover.Trigger>
        <Popover.Portal>
          <PopoverContent>
            <StyledInput
              type="time"
              onChange={(e) => onChange(e.target.value)}
            />
          </PopoverContent>
        </Popover.Portal>
      </Popover.Root>
    </Box>
  );
};

export default TimePicker;

import {
  Flex,
  Box,
  Avatar,
  TextField,
  Text,
  Button,
  Select,
} from "@radix-ui/themes";
import { keyframes, styled } from "@stitches/react";
import React, { useEffect, useState } from "react";
import colors from "./colors";
import { sampleImageUrl } from "../utils/constants";
import { toast } from "react-toastify";
import api from "../api/api";
import { getNames, omit } from "../utils";
import { useAppContext } from "../context/appContext";

const Container = styled(Box, {
  background: colors.background,
  padding: "20px 10px 20px 10px",
});

const Main = styled(Flex, {
  background: "#fff",
  height: "100vh",
  borderRadius: "5px",
  boxShadow: "0px 4px 10px 0px #D9D9D9",
});

const spin = keyframes({
  "0%": { transform: "rotate(0deg)" },
  "100%": { transform: "rotate(360deg)" },
});

const Loader = styled(Flex, {
  border: `4px solid ${colors.deepGray}`,
  borderTop: `4px solid ${colors.base}`,
  borderRadius: "50%",
  width: "40px",
  height: "40px",
  animation: `${spin} 1s linear infinite`,
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
});

const Settings = () => {
  const { user: platformUser } = useAppContext();
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);

  const notifySuccess = (msg) => toast.success(msg);
  const notifyError = (msg) => toast.error(msg);

  const getCurrentUser = async () => {
    try {
      setLoading(true);
      const response = await api.get("/user/current");
      setUser(response.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      notifyError(err.response.data?.error ?? "Error fetching user");
    }
  };

  const updateUser = async (e) => {
    e.preventDefault();
    setSaving(true);
    const payload = omit(user, [
      "password",
      "resetPasswordToken",
      "resetPasswordTokenExpiry",
    ]);
    try {
      await api.put(`/user/${user.id}`, {
        ...payload,
        projectIds: user.projects.map((project) => project.projectId),
      });
      notifySuccess("User updated successfully");
      setSaving(false);
    } catch (err) {
      notifyError(err.response.data?.error ?? "Error updating user");
      setSaving(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    getCurrentUser();
  }, []);

  if (loading) {
    return (
      <Container>
        <Main align="center" pt="90px" direction="column">
          <Loader />
        </Main>
      </Container>
    );
  }

  return (
    <Container>
      <Main align="center" pt="90px" direction="column">
        <Box>
          <Flex align="center" justify="start" mb="60px">
            <Avatar radius="full" size="6" src={sampleImageUrl} />
            <Text size="4" weight="bold" color="violet" ml="25px">
              {platformUser.name}
            </Text>
          </Flex>
          <form onSubmit={updateUser}>
            <Flex justify="start" gapX="60px">
              <Box minWidth="320px">
                <Text size="2" mt="3" weight="bold" color="violet">
                  First Name
                </Text>
                <TextField.Root
                  mt="10px"
                  type="text"
                  placeholder="First Name"
                  size="3"
                  name="firstName"
                  value={getNames(user.name).firstName}
                  onChange={handleChange}
                />
              </Box>
              <Box minWidth="320px">
                <Text size="2" mt="3" weight="bold" color="violet">
                  Phone number
                </Text>
                <TextField.Root
                  mt="10px"
                  type="text"
                  placeholder="+972 123 456 789"
                  size="3"
                  name="phoneNumber"
                  value={user?.phoneNumber ?? ""}
                  onChange={handleChange}
                />
              </Box>
            </Flex>
            <Flex justify="start" gapX="60px" mt="20px">
              <Box minWidth="320px">
                <Text size="2" mt="3" weight="bold" color="violet">
                  Last name
                </Text>
                <TextField.Root
                  mt="10px"
                  type="text"
                  placeholder="Last name"
                  size="3"
                  name="lastName"
                  value={getNames(user.name).lastName}
                  onChange={handleChange}
                />
              </Box>
              <Box minWidth="320px">
                <Text size="2" mt="3" weight="bold" color="violet">
                  Time zone
                </Text>
                <TextField.Root
                  mt="10px"
                  type="text"
                  placeholder="Jerusalem  (GMT +2)"
                  size="3"
                  name="timezone"
                  value={user?.timezone ?? ""}
                  onChange={handleChange}
                />
              </Box>
            </Flex>
            <Flex justify="center" align="center" gapX="20px" mt="100px">
              <Button
                radius="full"
                size="3"
                style={{ width: "200px" }}
                type="submit"
                disabled={saving}
                ml="60px"
              >
                {saving ? "Saving..." : "Save changes"}
              </Button>
              <Button
                radius="full"
                size="3"
                style={{ width: "200px" }}
                disabled
              >
                Go to dashboard
              </Button>
            </Flex>
          </form>
        </Box>
      </Main>
    </Container>
  );
};

export default Settings;

import React, { useEffect, useState } from "react";
import launchViewer from "./viewerFunctions";
import {
  Box,
  Button,
  Callout,
  Dialog,
  Flex,
  Select,
  Text,
} from "@radix-ui/themes";
import RebarVisualization from "./Rebar";
import axios from "axios";
import { useAppContext } from "../../context/appContext";
import { toast } from "react-toastify";
import { FaInfoCircle } from "react-icons/fa";
import api from "../../api/api";

const bucketName = "atom-construction-bucket-eu";
const isLocalhost = window.location.hostname === "localhost";

const request = axios.create({
  baseURL: window.location.href.includes("https://localhost")
    ? "https://localhost:5002"
    : isLocalhost
    ? "http://localhost:5002"
    : "https://web.atom.construction",
});

const Viewer = () => {
  const { selectedProject } = useAppContext();
  const [formData, setFormData] = useState({
    element: "",
    object: "",
    floor: "",
  });
  const [currentData, setCurrentData] = useState(null);
  const [open, setOpen] = useState(false);
  const [floors, setFloors] = useState([]);
  const [elements, setElements] = useState([]);
  const [objects, setObjects] = useState([]);

  const notifyError = (msg) => toast.error(msg);

  const handleChange = async (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });

    if (name === "floor") {
      setFormData({ ...formData, floor: value, element: "", object: "" });
      setElements([]);
      setObjects([]);
      if (value) {
        try {
          const response = await request.get("/api/folder/list-elements", {
            params: {
              bucketName,
              client: selectedProject?.client,
              project: selectedProject?.name,
              floor: value,
            },
          });
          setElements(response.data.elements);
        } catch (error) {
          console.error("Error fetching elements", error);
        }
      }
    }

    if (name === "element") {
      setFormData({ ...formData, element: value, object: "" });
      setObjects([]);
      if (value) {
        try {
          const response = await request.get("/api/folder/list-objects", {
            params: {
              bucketName,
              client: selectedProject?.client,
              project: selectedProject?.name,
              floor: formData?.floor,
              element: value,
            },
          });
          setObjects(response.data.objects);
        } catch (error) {
          console.error("Error fetching objects", error);
        }
      }
    }
  };

  const onFetch = (data) => {
    if (data === null) {
      notifyError("Couldn't find a scanned set for the AB");
      return;
    }
    setCurrentData(data);
    setOpen(true);
  };

  useEffect(() => {
    const fetchFloors = async () => {
      try {
        const response = await request.get("/api/folder/list-floors", {
          params: {
            bucketName,
            client: selectedProject?.client,
            project: selectedProject?.name,
          },
        });
        setFloors(response.data.floors);
      } catch (error) {
        console.error("Error fetching floors", error);
      }
    };
    fetchFloors();
  }, []);

  const hasFullData = formData.floor && formData.element && formData.object;

  useEffect(() => {
    const hierachy = {
      ...formData,
      client: selectedProject?.client,
      project: selectedProject?.name,
    };
    const documentId =
      "dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6bmV3LWR3Zy1idWNrZXQvZmxvb3IuZHdn";

    if (hasFullData) {
      launchViewer("viewerDiv", documentId, hierachy, onFetch);
    } else {
      console.warn("Hierarchy is incomplete. Viewer not fully operational.");
    }
  }, [formData]); // Empty dependency array to run once on mount

  return (
    <Box mt="20px">
      <Flex justify="center" align="center" mb="20px">
        <Flex direction="column">
          <Text size="3" color="violet" weight="bold">
            Floor
          </Text>
          <Select.Root
            size="3"
            name="floor"
            mt="3px"
            value={formData.floor}
            onValueChange={(val) => handleChange("floor", val)}
          >
            <Select.Trigger
              style={{ width: "320px" }}
              placeholder="Floor"
              required
            />
            <Select.Content style={{ width: "320px" }}>
              {floors.map((floor) => (
                <Select.Item value={floor} key={floor}>
                  {floor}
                </Select.Item>
              ))}
            </Select.Content>
          </Select.Root>
        </Flex>

        <Flex direction="column" ml="5px">
          <Text size="3" color="violet" weight="bold">
            Elements
          </Text>
          <Select.Root
            size="3"
            name="element"
            mt="3px"
            disabled={!formData.floor}
            value={formData.element}
            onValueChange={(val) => handleChange("element", val)}
          >
            <Select.Trigger
              style={{ width: "320px" }}
              placeholder="Element"
              required
            />
            <Select.Content style={{ width: "320px" }}>
              {elements.map((element) => (
                <Select.Item value={element} key={element}>
                  {element}
                </Select.Item>
              ))}
            </Select.Content>
          </Select.Root>
        </Flex>

        <Flex direction="column" ml="5px">
          <Text size="3" color="violet" weight="bold">
            Objects
          </Text>
          <Select.Root
            size="3"
            name="object"
            mt="3px"
            disabled={!formData.element}
            value={formData.object}
            onValueChange={(val) => handleChange("object", val)}
          >
            <Select.Trigger
              style={{ width: "320px" }}
              placeholder="Object"
              required
            />
            <Select.Content style={{ width: "320px" }}>
              {objects.map((object) => (
                <Select.Item value={object} key={object}>
                  {object}
                </Select.Item>
              ))}
            </Select.Content>
          </Select.Root>
        </Flex>
      </Flex>
      {!hasFullData ? (
        <Flex justify="center" align="center">
          <Callout.Root color="red">
            <Callout.Icon>
              <FaInfoCircle />
            </Callout.Icon>
            <Callout.Text>
              You will need to select a full hierarchy to preview viewer
            </Callout.Text>
          </Callout.Root>
        </Flex>
      ) : (
        <div
          style={{ position: "absolute", width: "100%", height: "85%" }}
          id="viewerDiv"
        />
      )}

      {open && (
        <Dialog.Root
          open={open}
          onOpenChange={() => setOpen((open) => (open = !open))}
        >
          <Dialog.Content maxWidth="600px" maxHeight="600px">
            <RebarVisualization data={currentData} />
          </Dialog.Content>
        </Dialog.Root>
      )}
    </Box>
  );
};

export default Viewer;

import React, { useState } from "react";
import {
  Box,
  Text,
  Flex,
  TextField,
  Link,
  Button,
  Checkbox,
} from "@radix-ui/themes";
import Logo from "./atom-logo.svg";
import { toast } from "react-toastify";
import { LuUser2, LuKeyRound } from "react-icons/lu";
import { useNavigate, useSearchParams } from "react-router-dom";
import api from "../api/api";

const ResetPassword = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);
  const [formData, setFormData] = useState({
    password: "",
    confirmPassword: "",
  });
  const token = searchParams.get("token");

  const notifyError = (msg) => toast.error(msg);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    const payload = {
      token,
      newPassword: formData.password,
    };
    try {
      await api.post("/auth/reset-password", payload);
      setSuccess(true);
    } catch (err) {
      notifyError(err.response?.data?.error ?? "Password reset unsuccessful");
    }
  };

  const passwordsMatch = formData.password === formData.confirmPassword;

  return (
    <Flex justify="center" align="center" minHeight="100vh">
      <Box
        style={{
          background: "#F8F8F8",
          border: "1px solid #A5A8A6",
          borderRadius: 10,
          padding: 60,
        }}
        width={{
          lg: 800,
          initial: "100vw",
        }}
        height={{
          lg: 670,
          initial: "100vh",
        }}
      >
        {success ? (
          <Box>
            <Flex justify="end">
              <Flex direction="column" align="center">
                <Box width="100px" height="100px">
                  <img
                    src={Logo}
                    alt="Atom logo"
                    style={{ width: "100px", height: "100px" }}
                  />
                </Box>
                <Text size="6" mt="3" weight="bold">
                  ATOM
                </Text>
              </Flex>
            </Flex>
            <Flex direction="column" justify="center" align="center" mt="70px">
              <Text size="5" weight="bold" align="center">
                All done, your password is changed !
              </Text>
              <Text size="3" align="center" mt="10px">
                You can now access your account from the login page
              </Text>
              <Button
                radius="full"
                size="4"
                style={{ width: "200px" }}
                mt="60px"
                onClick={() => navigate("/login")}
              >
                Go to login
              </Button>
            </Flex>
          </Box>
        ) : (
          <form onSubmit={handleResetPassword}>
            <Flex
              justify={{
                lg: "start",
                initial: "center",
              }}
              align="center"
              gapX="280px"
            >
              <Flex
                direction="column"
                display={{ lg: "flex", initial: "none" }}
              >
                <Text size="7" mb="3" weight="bold">
                  New Password
                </Text>
                <Text size="3">Enter new password</Text>
              </Flex>
              <Flex direction="column" align="center">
                <Box width="100px" height="100px">
                  <img
                    src={Logo}
                    alt="Atom logo"
                    style={{ width: "100px", height: "100px" }}
                  />
                </Box>
                <Text size="6" mt="3" weight="bold">
                  ATOM
                </Text>
              </Flex>
            </Flex>
            <Flex
              justify={{
                lg: "start",
                initial: "center",
              }}
              gapX={{
                lg: "30px",
                initial: "0",
              }}
              mt="80px"
            >
              <Box minWidth="320px">
                <TextField.Root
                  type="password"
                  placeholder="Password"
                  size="3"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  required
                >
                  <TextField.Slot side="right">
                    <LuKeyRound height="16" width="16" />
                  </TextField.Slot>
                </TextField.Root>
              </Box>
            </Flex>
            <Flex
              justify={{
                lg: "start",
                initial: "center",
              }}
              gapX={{
                lg: "30px",
                initial: "0",
              }}
              mt="20px"
            >
              <Box minWidth="320px">
                <TextField.Root
                  type="password"
                  placeholder="Confirm password"
                  size="3"
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  required
                >
                  <TextField.Slot side="right">
                    <LuKeyRound height="16" width="16" />
                  </TextField.Slot>
                </TextField.Root>
                {formData.confirmPassword && !passwordsMatch && (
                  <Text size="2" color="tomato">
                    Passwords do not match
                  </Text>
                )}
              </Box>
            </Flex>
            <Flex
              justify={{
                lg: "start",
                initial: "center",
              }}
              gapX={{
                lg: "30px",
                initial: "0",
              }}
              mt="60px"
              align="center"
            >
              <Button
                radius="full"
                size="3"
                style={{ width: "200px" }}
                type="submit"
                ml={{
                  lg: "60px",
                  initial: 0,
                }}
              >
                Save password
              </Button>
              <Flex
                gap="2"
                align="center"
                display={{ lg: "flex", initial: "none" }}
              >
                <Checkbox />
                <Text size="2">Remember your password</Text>
              </Flex>
            </Flex>
          </form>
        )}
      </Box>
    </Flex>
  );
};

export default ResetPassword;
